import React, { useEffect, useMemo, useState } from 'react';
import SelectForm from '../../components/selectForm/SelectForm';
import { FormProvider, useForm } from 'react-hook-form';
import Header from '../../components/header/header';
import axios from 'axios';
import ImageCard from '../../components/ImageCard/ImageCard';
import st from './disturb.module.scss';
import CreditContact from '../../components/CreditContact/CreditContact';
import { getFirstPay, GetMinMaxPercent } from '../../utils';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '../Retail/retail.module.scss';
import { api } from '../../config/api';

const dealerSchema = yup.object().shape({
    name: yup.string().required('Имя обязательно '),
    phone: yup.string().required('Номер телефона обязателен '),
    price: yup.number(),
    term: yup.number(),
    percent: yup.number().required(),
    initialPay: yup.number(),
    range: yup.number(),
    regions: yup.string().required('Необходимо выбрать регион'),
    city: yup.string().required('Необходимо выбрать город'),
    dealer: yup.string().required('Необходимо выбрать дилера'),
});

const DistributorWidget = () => {
    const [data, setData] = useState<any | null>(null);
    const queryParameters = new URLSearchParams(window.location.search);
    const hash = queryParameters.get('hash');
    const form = useForm({
        mode: 'onBlur',
        resolver: yupResolver(dealerSchema),
    });

    const { watch, setValue, getValues } = form;

    const termArray = useMemo(() => {
        if (data) {
            return data.programs.reduce((acc: any, item: any) => {
                if (acc.includes(item.term_to)) {
                    return acc;
                }
                return [...acc, item.term_to];
            }, []);
        }
    }, [data]);

    useEffect(() => {
        async function fetchData() {
            api.get(`/models/default/widget-data?hash=${hash}`).then(res =>
                setData(res.data?.data)
            );
        }

        if (hash) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (data) {
            setValue('price', data.price);
            setValue('term', termArray[0].toString());
            setValue(
                'percent',
                GetMinMaxPercent(data.programs, termArray[0]).minValue
            );
            setValue(
                'initialPay',
                getFirstPay(getValues('price'), getValues('percent'))
            );
        }
    }, [data]);

    useEffect(() => {
        if (!data) return;
        setValue(
            'initialPay',
            getFirstPay(getValues('price'), getValues('percent'))
        );
        setValue(
            'percent',
            GetMinMaxPercent(data.programs, getValues('term')).minValue
        );
    }, [watch('term')]);

    useEffect(() => {
        setValue(
            'initialPay',
            getFirstPay(getValues('price'), getValues('percent'))
        );
    }, [watch('percent')]);

    if (!hash) {
        return (
            <>
                <Header />
                <div
                    className={`${styled.appContainer} + ${styled.appContainer_noHash}`}
                >
                    <h1 style={{ textAlign: 'center' }}>
                        Некорректная или не действительная ссылка
                    </h1>
                </div>
            </>
        );
    }

    return data !== null ? (
        <>
            <Header brandLogo={data.brand_logo} />
            <div className={st.page}>
                <FormProvider {...form}>
                    <ImageCard
                        image={data.image}
                        model={data.name}
                        price={data.price}
                    />
                    <CreditContact
                        creditData={termArray}
                        banks={data.banks}
                        programs={data.programs}
                    />
                    <SelectForm />
                </FormProvider>
            </div>
            <p className={'notice'}>
                Сайт носит информационный характер и не является публичной
                офертой. За подробной информацией обращайтесь к официальным
                дилерам {data.brand} или оставьте заявку на сайте. Ознакомьтесь
                с кредитными программами
            </p>
        </>
    ) : (
        <p>Загрузка</p>
    );
};

export default DistributorWidget;
