import React, { useState } from 'react';
import styled from './simpleForm.module.scss';
import { useFormContext } from 'react-hook-form';
import CtrInput from '../controlledComponents/CtrInput/CtrInput';
import MaskedTextField from '../controlledComponents/maskInput/CtrMaskInput';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

const SimpleForm = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useFormContext();
    const queryParameters = new URLSearchParams(window.location.search);
    const hash = queryParameters.get('hash');
    const [isDisabled, setDisabled] = useState(false);
    const sendData = (data: any) => {
        setDisabled(true);
        axios
            .post('https://finext.extremeassist.ru/api/order/create-order', {
                client: {
                    fio: data.name,
                    phone: data.phone,
                },
                product: data.model,
                initial_pay_amount: data.initialPay,
                price: data.price,
                term: data.term,
                is_brand_calc: 1,
                source: 80,
                hash: hash,
            })
            .then(() => {
                NotificationManager.success(
                    'Ваша заявка отправлена',
                    'Отправлено'
                );
                setTimeout(() => {
                    window.location.href = document.referrer;
                }, 10000);
            })
            .catch(() => {
                NotificationManager.error('Что-то пошло не так', 'Ошибка');
            });
    };
    return (
        <div className={styled.form}>
            <h3>Контактные данные </h3>
            <div className={styled.inner}>
                <CtrInput
                    label={'Имя'}
                    placeholder={'Введите ФИО'}
                    {...register('name')}
                    error={errors?.name?.message}
                />

                <MaskedTextField
                    mask={'+{7}(000)-000-00-00'}
                    label={'Номер телефона'}
                    {...register('phone')}
                    error={errors?.phone?.message}
                />
                <button
                    type={'submit'}
                    className={styled.btn}
                    disabled={isDisabled}
                    onClick={handleSubmit(data => sendData(data))}
                >
                    Отправить заявку
                </button>

                <p className={styled.notice}>
                    Заполняя форму и нажимая кнопку отправить заявку, я принимаю
                    условия передачи информации
                </p>
            </div>
        </div>
    );
};

export default SimpleForm;
